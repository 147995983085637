// src/context/InstallPromptContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface BeforeInstallPromptEvent extends Event {
    readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
    prompt(): Promise<void>;
}

interface InstallPromptContextProps {
    promptEvent: BeforeInstallPromptEvent | null;
    setPromptEvent: (event: BeforeInstallPromptEvent | null) => void;
}

export const InstallPromptContext = createContext<InstallPromptContextProps>({
    promptEvent: null,
    setPromptEvent: () => {},
});

export const InstallPromptProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);

    useEffect(() => {
        const handler = (e: Event) => {
            e.preventDefault();
            setPromptEvent(e as BeforeInstallPromptEvent);
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    return (
        <InstallPromptContext.Provider value={{ promptEvent, setPromptEvent }}>
            {children}
        </InstallPromptContext.Provider>
    );
};
