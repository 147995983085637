import React, { useEffect, useRef, useState } from 'react';
import {
    createFirebaseUser,
    firebaseAuth,
    loginFirebaseUser,
    sendFbPasswordResetEmail,
    signInWithGoogle as signInWithGooglePopUp,
} from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import * as api from '../api/services';
import { User, Role, UserOrigin, Language, SubscriptionFormula } from '@/types/types';
import Api from '@/api/client';
import { getRealTimeCapital } from '@/api/services';

export interface AuthContextValue {
    user: User | null;
    signInWithGoogle: () => Promise<void>;
    signOut: () => void;
    hasLoadedUser: boolean;
    signUpWithGoogle: (role: Role) => Promise<void>;
    setUser: (user: User) => void;
    syncUser: () => Promise<void>;
    signUpWithEmailPass: (user: SignUpWithEmailParams) => Promise<void>;
    signInWithEmailPass: (email: string, password: string) => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
    setFromMentorId: (mentorId: string | null) => void;
    fromMentorId: string | null;
    setMayGoToExplorer: (mayGoToExplorer: boolean) => void;
    mayGoToExplorer: boolean;
}

export interface SignUpWithEmailParams {
    email: string;
    password: string;
    role: Role;
    pseudo: string;
    firstName: string;
    lastName: string;
    language: Language;
    mentorPrice?: string;
    subscriptionFormula?: SubscriptionFormula;
}

const defaultValue = {
    user: null,
    hasLoadedUser: false,
};

// @ts-expect-error car on passe un objet partiellement typé par défaut
const AuthContext = React.createContext<AuthContextValue>(defaultValue);

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const navigate = useNavigate();
    const [hasLoadedUser, setHasLoadedUser] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [fromMentorId, setFromMentorId] = useState<string | null>(null);
    const [mayGoToExplorer, setMayGoToExplorer] = useState(false);
    const hasCheckedUser = useRef(false);

    // Au montage, on écoute les changements d'état utilisateur Firebase
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            async (firebaseUser) => {
                if (hasCheckedUser.current) return;
                hasCheckedUser.current = true;

                if (firebaseUser) {
                    try {
                        // Initialiser l'API avec le token utilisateur
                        await Api.init(firebaseUser);

                        // Maintenant que le header Authorization est set, on peut appeler getUser()
                        const { data: currentUser } = await api.getUser();
                        // Si nécessaire, on set une superUserKey
                        if (currentUser.superUserKey) {
                            Api.setSuperUserKey(currentUser.superUserKey);
                        }
                        setUser(currentUser);
                    } catch (error) {
                        console.error("Error during initial user load:", error);
                        signOut();
                    }
                }
                setHasLoadedUser(true);
            }
        );
        return unsubscribe;
    }, []);

    useEffect(() => {
        const updateTotalCapital = async () => {
            if (!user?.id || user?.role !== Role.TRADER) return;
            try {
                const { data } = await getRealTimeCapital();
                setUser((prev) => prev ? { ...prev, totalCapital: data.capital } : null);
            } catch (error) {
                console.error("Error updating capital:", error);
            }
        };

        if (user?.id && user?.role === Role.TRADER) {
            updateTotalCapital();
            const interval = setInterval(updateTotalCapital, 5 * 60 * 1000);
            return () => clearInterval(interval);
        }
    }, [user?.id, user?.role]);

    const signUpWithGoogle = async (role: Role) => {
        try {
            const { user: firebaseUser } = await signInWithGooglePopUp();
            await Api.init(firebaseUser);
            const { data: newUser } = await api.createUser({
                role,
                origin: UserOrigin.GOOGLE,
            });
            setUser(newUser);
        } catch (error) {
            console.error("Error during Google signup:", error);
            await signOut();
            throw error;
        }
    };

    const signInWithGoogle = async () => {
        try {
            const { user: firebaseUser } = await signInWithGooglePopUp();
            await Api.init(firebaseUser);
            const { data: currentUser } = await api.getUser();
            if (currentUser.superUserKey) {
                Api.setSuperUserKey(currentUser.superUserKey);
            }
            setUser(currentUser);
        } catch (error) {
            console.error("Error during Google signin:", error);
            await signOut();
            throw error;
        }
    };

    const signUpWithEmailPass = async ({
                                           firstName,
                                           lastName,
                                           pseudo,
                                           role,
                                           email,
                                           password,
                                           language,
                                           mentorPrice,
                                           subscriptionFormula,
                                       }: SignUpWithEmailParams) => {
        try {
            const { user: firebaseUser } = await createFirebaseUser(email, password);
            await Api.init(firebaseUser);
            const { data: newUser } = await api.createUser({
                role,
                origin: UserOrigin.EMAIL_PASSWORD,
                firstName,
                lastName,
                pseudo,
                language,
                mentorPrice,
                subscriptionFormula,
            });
            setUser(newUser);
            if (newUser.role === Role.MENTEE) setMayGoToExplorer(true);
        } catch (error) {
            console.error("Error during email/password signup:", error);
            await signOut();
            throw error;
        }
    };

    const signInWithEmailPass = async (email: string, password: string) => {
        try {
            const { user: firebaseUser } = await loginFirebaseUser(email, password);
            await Api.init(firebaseUser);
            const { data: currentUser } = await api.getUser();
            if (currentUser.superUserKey) {
                Api.setSuperUserKey(currentUser.superUserKey);
            }
            setUser(currentUser);
            if (currentUser.role === Role.MENTEE) setMayGoToExplorer(true);
        } catch (error) {
            console.error("Error during email/password signin:", error);
            await signOut();
            throw error;
        }
    };

    const signOut = async () => {
        await firebaseAuth.signOut();
        Api.signOut();
        setUser(null);
    };

    const resetPassword = (email: string) => {
        return sendFbPasswordResetEmail(email);
    };

    const syncUser = async () => {
        const { data: newUserData } = await api.getUser();
        // On préserve le totalCapital s’il existe déjà
        setUser((prevUserData) => ({
            totalCapital: prevUserData?.totalCapital,
            ...newUserData,
        }));
    };

    const value: AuthContextValue = {
        user,
        signInWithGoogle,
        signOut,
        hasLoadedUser,
        signUpWithGoogle,
        signUpWithEmailPass,
        signInWithEmailPass,
        resetPassword,
        setUser,
        syncUser,
        setFromMentorId,
        fromMentorId,
        setMayGoToExplorer,
        mayGoToExplorer,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
