import { Exchange, ProductCategory } from '@/types/types';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUB_KEY;

export const APP_HOST_URL = process.env.REACT_APP_HOST_URL;

export const searchCategoryToExchange = {
    [ProductCategory.STOCK]:
        'TSX,AMEX,NASDAQ,NYSE,EURONEXT,XETRA,NSE,LSE,STO,SHZ,HEL,SHH,JPX,OSE,SIX,HKSE,CPH,MIL,KSC,TAI',
    [ProductCategory.COMMODITY]: 'COMMODITY',
    [ProductCategory.CRYPTO]: 'CRYPTO',
    [ProductCategory.FOREX]: 'FOREX',
};

export const exchangeToGroupMap: Record<Exchange, ProductCategory> = {
    TSX: ProductCategory.STOCK,
    AMEX: ProductCategory.STOCK,
    NASDAQ: ProductCategory.STOCK,
    NYSE: ProductCategory.STOCK,
    EURONEXT: ProductCategory.STOCK,
    XETRA: ProductCategory.STOCK,
    NSE: ProductCategory.STOCK,
    LSE: ProductCategory.STOCK,
    STO: ProductCategory.STOCK,
    SHZ: ProductCategory.STOCK,
    HEL: ProductCategory.STOCK,
    SHH: ProductCategory.STOCK,
    JPX: ProductCategory.STOCK,
    OSE: ProductCategory.STOCK,
    SIX: ProductCategory.STOCK,
    HKSE: ProductCategory.STOCK,
    CPH: ProductCategory.STOCK,
    MIL: ProductCategory.STOCK,
    KSC: ProductCategory.STOCK,
    TAI: ProductCategory.STOCK,
    COMMODITY: ProductCategory.COMMODITY,
    CRYPTO: ProductCategory.CRYPTO,
    FOREX: ProductCategory.FOREX,
};

export const NO_AVATAR_URL =
    'https://firebasestorage.googleapis.com/v0/b/slice-359019.appspot.com/o/avatar-placeholder.png?alt=media&token=7ece7e96-e29a-41a7-8297-57b4b7392ef9';

export const SUBSCRIPTION_PRICE = 24.99;

export const SUBSCRIPTION_PRICE_VAT = 4.99;

export const SUBSCRIPTION_PRICE_PLUS_VAT = 29.99;

const FMP_PROD_SUFFIX_TO_EOD = {
    AS: 'AS',
    BE: 'BE',
    BR: 'BR',
    CO: 'CO',
    PA: 'PA',
    MC: 'MC',
    F: 'F',
    HM: 'HM',
    HK: 'HK',
    HE: 'HE',
    IR: 'IR',
    IC: 'IC',
    IS: 'IS',
    T: 'TSE',
    MI: 'MI',
    MU: 'MU',
    LS: 'LS',
    OL: 'OL',
    SS: 'SHG',
    SG: 'STU',
    SW: 'SW',
    TW: 'TW',
    SZ: 'SHE',
    TA: 'TA',
    ST: 'ST',
    VI: 'VI',
    WA: 'WAR',
};

const FMP_EXCH_TO_EOD = {
    AMEX: 'US',
    NYSE: 'US',
    NASDAQ: 'US',
    LSE: 'LSE',
    TSX: 'TO',
    XETRA: 'XETRA',
};

export const DELAYED_MARKETS = [
    "EURONEXT",
    "XETRA",
    "NSE",
    "STO",
    "SHZ",
    "HEL",
    "LSE",
    "SHH",
    "JPX",
    "OSE",
    "SIX",
    "HKSE",
    "CPH",
    "MIL",
  ];
