import axios, { AxiosError, AxiosInstance } from 'axios';
import { User as FirebaseUser } from 'firebase/auth';
import { API_BASE_URL } from '@/constants';
import * as Sentry from '@sentry/react';

class ApiClient {
    user: FirebaseUser | null;
    axiosInstance: AxiosInstance;
    superUserKey: string | null;

    constructor() {
        this.user = null;
        this.superUserKey = null;
        this.axiosInstance = axios.create({
            baseURL: API_BASE_URL,
            timeout: 20000,
            headers: { 'Content-Type': 'application/json' },
        });

        this.handle401 = this.handle401.bind(this);

        // Intercepteur de requête pour ajouter superUserKey aux params si défini
        this.axiosInstance.interceptors.request.use((config) => {
            if (this.superUserKey) {
                config.params = {
                    ...config.params,
                    superUserKey: this.superUserKey,
                };
            }
            return config;
        });

        // Intercepteur de réponse pour gérer les erreurs 401
        this.axiosInstance.interceptors.response.use(
            (response) => response,
            this.handle401
        );
    }

    /**
     * Initialise le client avec un utilisateur Firebase (connecté),
     * et récupère son token d'accès pour le stocker dans axios.
     */
    async init(firebaseUser: FirebaseUser) {
        const accessToken = await firebaseUser.getIdToken();
        this.setAccessToken(accessToken);
        this.user = firebaseUser;
    }

    /**
     * Définit la superUserKey, qui sera envoyée dans les paramètres de chaque requête.
     */
    setSuperUserKey(key: string | null) {
        this.superUserKey = key;
    }

    /**
     * Définit le token d'accès dans le header Authorization de axios.
     * On ajoute un prefix "Bearer " par convention.
     */
    setAccessToken(token?: string) {
        if (token) {
            this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete this.axiosInstance.defaults.headers.common['Authorization'];
        }
    }

    /**
     * Réinitialise le client, retire l'utilisateur et le token.
     * Idéalement appelé lors d'un signOut.
     */
    signOut() {
        this.user = null;
        this.superUserKey = null;
        delete this.axiosInstance.defaults.headers.common['Authorization'];
    }

    /**
     * Intercepteur d'erreur 401 : tente de refresh le token.
     * - Vérifie si l'erreur est vraiment un 401 et si on n'a pas déjà tenté un retry.
     * - Rafraîchit le token avec `getIdToken(true)`.
     * - Réessaye la requête originale une fois.
     */
    async handle401(e: AxiosError) {
        if (e.response?.status !== 401 || (e.config as any)?._is401Retry) {
            // Erreur non-401 ou déjà réessayée, on envoie à Sentry et on rejette.
            Sentry.captureException(e);
            return Promise.reject(e);
        }

        // 401 non réessayé. On tente de refresh le token si on a un utilisateur.
        if (this.user && e.response?.status === 401) {
            try {
                const newToken = await this.user.getIdToken(true);
                const bearerToken = `Bearer ${newToken}`;
                this.axiosInstance.defaults.headers.common['Authorization'] = bearerToken;

                // Modifie la config de la requête pour inclure le nouveau token.
                e.config.headers = {
                    ...e.config.headers,
                    Authorization: bearerToken,
                };
                (e.config as any)._is401Retry = true; // on marque la requête comme réessayée

                // On retente la requête originale avec le nouveau token
                return this.axiosInstance(e.config);
            } catch (_e) {
                // Impossible de rafraîchir le token, on rejette l'erreur
                Sentry.captureException(_e);
                return Promise.reject(_e);
            }
        }

        // Si pas d'utilisateur ou autre cas, on rejette
        return Promise.reject(e);
    }
}

const ApiClientInstance = new ApiClient();

// Exporter directement l'instance axios si besoin
export const api = ApiClientInstance.axiosInstance;

export default ApiClientInstance;
