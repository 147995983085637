// src/serviceWorkerRegistration.ts

// Ce code vient en partie des anciens templates de CRA
/* eslint-disable no-console */

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] est l'IPv6 localhost
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8
    window.location.hostname.match(
        /^127(?:\.[0-9]+){0,2}\.[0-9]+$/
    )
);

type Config = {
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
    if ('serviceWorker' in navigator) {
        const publicUrl = new URL(
            process.env.PUBLIC_URL,
            window.location.href
        );
        if (publicUrl.origin !== window.location.origin) {
            return;
        }

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // Ex: en mode localhost
                checkValidServiceWorker(swUrl, config);
                navigator.serviceWorker.ready.then(() => {
                    console.log('Service worker ready.');
                });
            } else {
                // En prod
                registerValidSW(swUrl, config);
            }
        });
    }
}

function registerValidSW(swUrl: string, config?: Config) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            if (config && config.onSuccess) {
                config.onSuccess(registration);
            }
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // Nouvelle version dispo
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // Contenu mis en cache
                            console.log('Content is cached for offline use.');
                        }
                    }
                };
            };
        })
        .catch((error) => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' }
    })
        .then((response) => {
            // Vérification si le service worker existe
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                // SW inexistant
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Il existe bien, on enregistre
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log('No internet connection. App is running in offline mode.');
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}
